.threeCollumImages__container {
    @apply bg-white drop-shadow-md rounded-md cursor-pointer p-3; 
}
.threeCollumImages__wrapper {
    @apply flex items-center justify-center;
}
.threeCollumImages__wrapper img {
    @apply w-full aspect-auto object-cover object-center;
}
.threeCollumImages__wrapper h1 {
    @apply text-xs font-light tracking-wide uppercase text-slate-500;
}