.widgetdetails__container {
}
.widgetdetails__row--one {
    @apply text-xl font-semibold tracking-wide uppercase bg-white shadow-lg p-4 rounded-md mb-4 flex items-center justify-between sticky top-0 z-10;
}
.widgetdetails__row--two {
    @apply grid grid-cols-3 gap-4;
}
.widgetdetails__row--two .widgetdetails__col--one {
    @apply w-full h-[78vh] overflow-y-scroll sticky rounded-md top-20;
}
.widgetdetails__row--two
    .widgetdetails__col--one
    .widgetdetails__col--one-wrapper {
    @apply bg-white rounded-md p-4 h-fit shadow-lg w-full;
}
/* .widgetdetails__row--two .widgetdetails__col--one::-webkit-scrollbar {
    display: none;
}
.widgetdetails__row--two .widgetdetails__col--one {
    -ms-overflow-style: none;
    scrollbar-width: none; 
} */
.widgetdetails__row--two .widgetdetails__col--two {
    @apply col-span-2 bg-white rounded-md shadow-lg p-4 h-fit;
}
.widgetdetails__row--two .widgetdetails__col--two .input__field {
    @apply w-full h-32 border-2 border-dotted rounded-md flex items-center justify-center cursor-pointer;
}
.widgetdetails__row--two .widgetdetails__col--three {
    @apply bg-white rounded-md shadow-lg p-4 max-h-[78vh] min-h-[20vh] overflow-y-scroll sticky top-20;
}
/* .widgetdetails__row--two .widgetdetails__col--three::-webkit-scrollbar {
    display: none;
}
.widgetdetails__row--two .widgetdetails__col--three {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
} */
.indivisual_widget {
}
.indivisual_widget .name {
    @apply text-center capitalize tracking-wider border-b pb-3;
}
.widgetdetails__col--three {
}
.widgetdetails__col--three .indivisual_widget {
}
.widgetdetails__col--three .indivisual_widget form {
    @apply grid grid-cols-1 gap-4;
}
.delete_icon {
    @apply bg-white text-red-500 absolute -top-3 -right-3 rounded-full p-1 z-10 shadow-lg cursor-pointer;
}
.choose__images--btn {
    @apply border border-dotted w-full h-12 rounded-md shadow-md bg-gray-200 p-2 flex items-center justify-center capitalize tracking-wider text-sm text-gray-600 cursor-pointer mt-2;
}
