.sidebar__container {
    @apply min-h-screen bg-white;
}
.logo__wrapper {
    @apply w-full h-full flex items-center justify-center border-b mb-3 border-gray-100;
}
.logo {
    @apply uppercase text-2xl;
}
.sidebar__row {

}
.sidebar__item--wrapper {
    @apply px-4;
}
