.add-menu input {
    @apply w-full outline-none py-2 px-3 shadow-inner bg-gray-100 rounded-md tracking-wide;
}
.add-menu button {
    @apply rounded-none h-12;
}
.add-menu .btn {
    @apply w-full bg-green-500 font-thin tracking-wide uppercase shadow text-white transition-all ease-in-out duration-100 delay-100 hover:bg-green-500 drop-shadow-md hover:drop-shadow-none hover:shadow-inner;
}
.add-menu form {
    @apply flex flex-col mx-0 p-0;
}

.menu_nest .pagedetails__row--two .pagedetails__col--two {
    @apply col-span-3 bg-gray-300 overflow-hidden rounded-md shadow-lg p-4 h-fit;
}

.menu_nest .pagedetails__row--two .pagedetails__col--one .pagedetails__col--one-wrapper{
    @apply bg-white rounded-md p-4 h-fit shadow-lg w-full;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.editOptions.indivisual_widget {
    @apply bg-white z-50 mb-4 rounded-md max-w-sm

}

.editOptions.indivisual_widget form {
    @apply grid grid-cols-1 gap-4 p-4;
}

.editOptions.indivisual_widget .name {
    @apply text-center capitalize tracking-wider border-b pb-3;
}

.dissabledSliderForm__btn {
    @apply w-full bg-gray-500 text-white h-12 uppercase tracking-wide shadow-lg;
}
