.widget_filter-row {
    
}
.widget_filter-container {
    @apply h-10;
}
.widget_filter-col {
    @apply grid grid-cols-3 divide-x h-full bg-white shadow-md rounded-md mb-4;
}
.page_type-col {
    @apply grid grid-cols-4 divide-x h-full bg-white shadow-md rounded-md;
}
.filter_item {
    @apply text-center text-sm tracking-wider capitalize flex items-center justify-center cursor-pointer;
}
.filter_item-two {
    @apply text-center text-xs tracking-wider capitalize flex items-center justify-center cursor-pointer;
}