.dashboard__container {
    @apply flex items-center w-full h-[80vh] justify-center;
}
.dashboard__content {

}
.dashboard__content h1 {
    @apply text-4xl font-extralight tracking-tight capitalize text-gray-500 text-center;
}
.dashboard__content p {
    @apply text-xs font-light tracking-wide capitalize text-gray-500 text-center;
}