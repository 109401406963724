.menupage__container {

}
.menupage__row--one {
    @apply p-6 grid grid-cols-2;
}
form {
    @apply p-6 rounded-md bg-white grid grid-cols-3 items-center gap-6;
}
input {
    @apply w-full outline-none col-span-2 py-2 px-3 shadow-inner bg-gray-100 rounded-md tracking-wide;
}
.btn {
    @apply bg-green-500 py-2 rounded-md tracking-wide uppercase shadow text-white font-semibold transition-all ease-in-out duration-100 delay-100 hover:bg-green-500 drop-shadow-md hover:drop-shadow-none hover:shadow-inner;
}
.menupage__row--two{
    @apply p-6 grid grid-cols-3;
}
.menupage__row--two ul {
    @apply col-span-2;
}
.menupage__row--two ul li {
    @apply px-6 py-3 rounded-md bg-white mb-4 last:mb-0 shadow-lg cursor-pointer capitalize tracking-wide flex items-center justify-between;
}
.btns__wrapper {
    @apply flex items-center gap-4;
}
.duplicate__btn {
    @apply text-white bg-green-400 p-3 rounded-full cursor-pointer shadow-lg shadow transition-all ease-in-out duration-100 delay-100 hover:drop-shadow-none hover:shadow-inner;
}
.edit__btn {
    @apply text-white bg-blue-400 p-3 rounded-full cursor-pointer shadow-lg shadow transition-all ease-in-out duration-100 delay-100 hover:drop-shadow-none hover:shadow-inner;
}
.delete__btn {
    @apply text-white bg-red-400 p-3 rounded-full cursor-pointer shadow-lg shadow transition-all ease-in-out duration-100 delay-100 hover:drop-shadow-none hover:shadow-inner;
}