.mainSliderForm__container {
    @apply p-0;
}
.mainSliderForm__container .title {
    @apply text-center border-b pb-2;
}
.mainSliderForm__container .checkbox{
    @apply flex items-center gap-4;
}
.mainSliderForm__container .checkbox input {
    @apply w-auto cursor-pointer;
}
.mainSliderForm__btn {
    @apply w-full bg-green-500 text-white h-12 uppercase tracking-wide shadow-lg;
}