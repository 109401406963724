@tailwind base;
@tailwind components;
@tailwind utilities;

.ck-content {
    min-height: 500px;
    max-height: auto;
}

.tox-statusbar__branding {
    @apply !hidden;
}

.chock-icon * {
    @apply stroke-black/60 transition-colors;
}
.chock-icon:hover * {
    @apply stroke-black;
}

.page-loader {
    @apply fixed top-0 left-0 w-full h-full py-4 bg-white/70;
    @apply z-[999] flex justify-center items-center;
    backdrop-filter: saturate(180%) blur(5px);
}

.page-loader .bg {
    @apply w-full opacity-10;
}
