.contenteditor__container {
    @apply fixed inset-0 z-20;
    background-color: black; 
}
.image__popup--wrapper h1{
    @apply text-[2em]
}
.image__popup--wrapper h2{
    @apply text-[2em]
}

.image__popup--wrapper h3{
    @apply text-[1.5em]
}
.image__popup--wrapper h4{
    @apply text-[1.17em]
}